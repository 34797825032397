import React, { Component } from 'react';







class CookieBanner extends Component {



    render(){


        return(
            <div className="banner-cookie-container">
                <div className="cookie-banner-content">
                    <h3>Ce site web utilise des cookies</h3>
                    <p></p>

                </div>
            </div>
        )
    }
}


export default CookieBanner;